.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 98%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.section {
  &.no-pad {
    padding: 0 !important;
  }
  &.no-pad-bot {
    padding-bottom: 0 !important;
  }
  &.no-pad-top {
    padding-top: 0 !important;
  }
}
