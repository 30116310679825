@import '../helpers';

.cdk-overlay-pane {
  min-width: 200px !important;
}

mat-option {
  @include helpers--text-dots;
}

.mat-select__panel--blue {
  .mat-option {
    font-weight: 500;
    &.mat-selected:not(.mat-option-disabled) {
      color: var(--dark-secondary-color);
      background: white;
    }
  }
}

.mat-select__panel--256 {
  width: 256px !important;
}
