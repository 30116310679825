@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import 'variables';
@import 'colors';
@import 'forms';
@import 'buttons';
@import 'helpers';
@import 'typography';
@import 'grid';
@import 'accessibility';
@import 'material/index.scss';

html {
  overflow: hidden;
  height: 100vh;
  background-color: $white;
}

body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: $primary-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: var(--font-default);
  color: $text-dark;

  // enable only for selected controls
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  &.blue-theme {
    background: linear-gradient(95deg, #033683, #0a3e8c 19%, #5893f6) no-repeat
      fixed;
  }
}

.screen-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.screen-full-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  pointer-events: auto !important;
  background-color: $extra-light;
}

.screen-top-right {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100000;
}

.screen-dropdown-boundary {
  position: relative;
  top: 100px;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.row.row-no-margin {
  margin: 0 !important;
}

.toast {
  &.error {
    background-color: $dark-error-color;
    color: $white;
  }

  &.warn {
    background-color: $error-color;
    color: $white;
  }

  &.info {
    background-color: $success-color;
    color: $white;
  }
}

.fullscreen-y-scroll {
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

div.section.body {
  padding-bottom: 8.857rem;
  position: fixed;
  width: 100%;
  height: calc(100vh - 4.572rem);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  .row {
    margin-bottom: 10px;
  }
}

.tertiary-content {
  color: $grey-color;
}

// Images (circle)

div.round-image {
  overflow: hidden;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.round-image img {
  height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
img.circle {
  object-fit: cover;
  -o-object-fit: cover;
}

// Context menu dropdown

#context-action-dropdown.dropdown-content {
  max-width: 70vw;
  min-width: 40vw;
  padding-top: $header-height;
  color: $text-dark !important;

  a {
    color: $text-dark !important;
  }
}

// Mobile Safari issue fixes

.dropdown-content {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}

.sidenav-overlay {
  left: 300px; // workaround so overlay does not cover .sidenav on Mobile Safari
}

// Positoning extension
.halign-wrapper {
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-flow: column;
}
