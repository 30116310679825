// Primary colors
// -------------------------
$primary-color: #435275 !default;
$primary-color-alt: #435174 !default;
$secondary-color: #4c7be5 !default;
$dark-secondary-color: #2c5bc5 !default;
$ripple-color: #4c7be5aa !default;
$success-color: #57ab00 !default;
$success-color-alt: #09a869 !default;
$error-color: #ff6e19 !default;
$dark-error-color: #700063 !default;
$link-color: #fff !default;
$grey-color: #cccccc !default;
$light-grey-color: #f4f4f4 !default;
$white: #fff;
$mildly-light: rgba(255, 255, 255, 0.8);
$light: rgba(255, 255, 255, 0.5);
$extra-light: rgba(255, 255, 255, 0.2);
$text-dark: #435275;
$text-grey: #9e9e9e;

// Figma colors
// ------------
$black-1: #2f3036;
$black-2: #1c1c1e;
$blue-1: #0060c6 !default;
$grey-1: #c5c6cc;
$grey-2: #435374;
$grey-3: #415467;
$grey-4: #e1e1e1;
$grey-5: #bbc2c7;

:root {
  --brand-primary: #578ec9;
  --modento-green-sea: #27acab;
  --modento-green-warm: #22a77f;
  --primary-color: #435275;
  --secondary-color: #4c7be5;
  --dark-secondary-color: #2c5bc5;
  --ripple-color: #4c7be5aa;
  --success-color: #57ab00;
  --error-color: #ff6e19;
  --dark-error-color: #700063;
  --link-color: #fff;
  --grey-color: #cccccc;
  --light-grey-color: #f4f4f4;
}
