@import 'colors';
@import 'variables';

button {
  @extend .white;
  font-weight: bold !important;
  color: $text-dark !important;
  border-radius: $button-radius !important;

  &.app-button--basic {
    background-color: transparent !important;
    color: var(--brand-primary) !important;
  }
}

a.btn {
  @extend .white;
  font-weight: bold !important;
  color: $text-dark !important;
  border-radius: $button-radius !important;

  &.btn-floating {
    border-radius: 50% !important;
  }
}

.fixed-action-btn > a > i {
  color: $text-dark !important;
}

button,
a.btn {
  @extend .disable-select;

  &.btn-circle {
    border-radius: 50% !important;
    width: 36px;
    height: 36px;
    align-content: center;
    text-align: center;
    vertical-align: middle;
    padding: 0;
  }
}

.btn-large {
  font-size: 1.286rem;
}

.btn-x-large {
  font-size: 1.714em;
  height: auto;
  padding: 20px 50px;
}

/*
 When creating your CSS selector,
 change "brown" to something of your choosing
 */
.waves-effect.waves-primary .waves-ripple {
  /* The alpha value allows the text and background color
  of the button to still show through. */
  background-color: $ripple-color;
}
