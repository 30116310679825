mat-form-field.mat-form-field {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-flex {
    padding: 0 12px;
    align-items: center;
    background: transparent;
  }

  .mat-form-field-infix {
    border-top: 0;
    padding: 0;
    height: 42px;
    display: flex;
    align-items: center;
  }

  .mat-form-field-underline {
    display: none;
  }

  &.vd-form-field--outline {
    // TODO setup to project
    //border: 1px solid var(--blue-intensive);
    //color: var(--blue-intensive);

    border-radius: 4px;
    height: 42px;

    input {
      font-size: 14px;
      font-family: var(--font-default);
    }

    .mat-form-field-label-wrapper {
      top: -0.54375em;
    }

    .mat-form-field-label {
      // color: var(--blue-intensive);
      font-family: var(--font-default);
      span {
        display: inline-block;
        padding: 0 4px;
        box-shadow: 0 0 3px 2px white;
        background: white;
      }
    }

    mat-select-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // color: var(--blue);
    }
  }

  &.vd-form-field--filled {
    border: none;
    border-radius: 4px;
    // background: var(--green-form);
    //color: var(--grey-light);
    input {
      font-size: 16px;
      font-family: var(--font-default);
    }

    .mat-form-field-label-wrapper {
      top: -0.94375em;
    }

    .mat-form-field-label {
      //color: var(--grey-light);
      font-family: var(--font-default);
      margin-top: 7px;
      span {
        display: inline-block;
        //padding: 0 4px;
        //box-shadow: 0 0 3px 2px white;
        //background: white;
      }
    }
  }

  &.vd-form-field--filled-grey {
    border: 1px solid #e4e9f2;
    border-radius: 4px;
    //background: var(--grey-form);
    //color: var(--grey-light);
    input {
      font-size: 14px;
      font-family: var(--font-default);
    }

    .mat-form-field-label-wrapper {
      top: -0.94375em;
    }
  }

  &.vd-form-field--textarea {
    .mat-form-field-flex {
      padding: 0;

      .mat-form-field-infix {
        height: auto;
      }
    }

    .mat-form-field-label {
      padding-left: 12px;
    }

    textarea {
      padding: 12px;
      font-family: var(--font-default);
    }
  }

  .vd-form-field__error-message {
    // color: var(--pink);
  }

  mat-select {
    font-size: 14px;
  }
}
