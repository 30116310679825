@import 'colors';
@import 'variables';

.blue-theme {
  form .col > p,
  form .col .col > p,
  form .col > span {
    color: $white !important;
    margin-top: 0 !important;
  }

  // input fields styling
  .input-field >,
  .input-field.file-field,
  .input-field > div.select-wrapper > {
    @extend .white-text;

    /* label color */
    label {
      color: $white !important;
    }
    label:not(.label-icon).active {
      transform: translateY(-14px) scale(0.9) !important;
    }
    /* label underline focus color */
    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='password'],
    input[type='search'] {
      color: $white !important;
      border-bottom: 1px solid $light !important;
      box-shadow: 0 1px 0 0 $light !important;
    }
    /* label focus color */
    input[type='text']:focus + label,
    input[type='email']:focus + label,
    input[type='number']:focus + label,
    input[type='tel']:focus + label,
    input[type='password']:focus + label,
    input[type='search']:focus + label {
      color: $white !important;
      font-weight: bold !important;
    }
    /* label underline focus color */
    input[type='text']:focus,
    input[type='email']:focus,
    input[type='number']:focus,
    input[type='tel']:focus,
    input[type='password']:focus,
    input[type='search']:focus {
      border-bottom: 1px solid $white !important;
      box-shadow: 0 1px 0 0 $white !important;
    }
    /* valid color */
    input[type='text'].valid,
    input[type='email'].valid,
    input[type='number'].valid,
    input[type='tel'].valid,
    input[type='password'].valid,
    input[type='search'].valid {
      border-bottom: 1px solid $white !important;
      box-shadow: 0 1px 0 0 $white !important;
    }
    /* invalid color */
    input[type='text'].invalid,
    input[type='email'].invalid,
    input[type='number'].invalid,
    input[type='tel'].invalid,
    input[type='password'].invalid,
    input[type='search'].invalid {
      border-bottom: 1px solid $error-color !important;
      box-shadow: 0 1px 0 0 $error-color !important;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }

    ::placeholder {
      color: $light !important;
    }

    div.btn {
      @extend .white;
      border-radius: $button-radius !important;
      color: $text-dark !important;
      font-weight: bold;
    }

    .select-wrapper {
      .caret {
        fill: $white;
      }

      & + label {
        font-size: 0.9rem;
      }
    }

    .helper-text.invalid {
      color: $error-color !important;
    }
  }

  // radio styling
  input[type='radio'] {
    &:checked + span:after,
    &.with-gap:checked + span:before,
    &.with-gap:checked + span:after {
      border: 2px solid $white !important;
    }

    & + span:after,
    &.with-gap + span:before,
    &.with-gap + span:after {
      border: 1px solid $white !important;
    }

    &:checked + span:after,
    &.with-gap:checked + span:after {
      background-color: $white !important;
    }

    & + span {
      color: $white !important;
    }
  }

  // checkbox styling
  input[type='checkbox'].filled-in {
    &:checked + span:not(.lever):after {
      border: 2px solid $white !important;
      background-color: $white !important;
    }

    &:checked + span:not(.lever):before {
      border-right-color: $secondary-color !important;
      border-bottom-color: $secondary-color !important;
    }

    &:not(:checked) + span:not(.lever):after {
      border-color: $white !important;
    }
  }

  label.for-checkbox {
    color: $white !important;
  }

  .select-dropdown {
    li.disabled span {
      color: $text-grey !important;
    }

    li.selected:not(.disabled) span {
      color: $secondary-color !important;
    }

    li span {
      color: $text-dark !important;
    }
  }

  p.fixed-label {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: $white;

    &.focused {
      font-weight: bold;
    }
  }
}
