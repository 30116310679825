/* You can add global styles to this file, and also import other style files */
@import '~materialize-css/sass/components/_color-variables.scss';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'scss/colors';
@import '~materialize-css/sass/materialize.scss';
@import 'scss/style';
@import '~animate.css/animate.min.css';

html,
body {
  height: 100%;
  overflow: hidden;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Regular.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-BoldItalic.ttf')
    format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Black.ttf')
    format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Regular_1.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Bold.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Red Hat Display';
  src: url('assets/fonts/Red_Hat_Display/RedHatDisplay-Italic.ttf')
    format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('~material-icons/iconfont/MaterialIcons-Regular.eot');
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('~material-icons/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
    url('~material-icons/iconfont/MaterialIcons-Regular.woff') format('woff'),
    url('~material-icons/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
  text-rendering: optimizeLegibility;
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
