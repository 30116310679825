.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 10px;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 10px;
}
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.allow-select {
  user-select: text !important; /* supported by Chrome and Opera */
  -webkit-user-select: text !important; /* Safari */
  -khtml-user-select: text !important; /* Konqueror HTML */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* Internet Explorer/Edge */
}

.helpers--text-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

@mixin helpers--text-dots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
