.mat-icon {
  &.mat-icon-20 {
    width: 20px;
    height: 20px;
  }
  &.mat-icon-36 {
    width: 36px;
    height: 36px;
  }
}
