@import 'colors';

:root {
  --font-default: 'Red Hat Display', sans-serif;
}

.helper-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 0.857em;

  &.invalid {
    color: $error-color !important;
  }

  &.primary {
    color: $white !important;
  }

  &.light {
    color: $light !important;
  }
}

table {
  color: $white !important;
}

span.required {
  font-size: x-small;
  font-weight: bold;
  vertical-align: super;
}

.text-light {
  color: $light !important;
}

.text-extra-light {
  color: $extra-light !important;
}

.text-error {
  color: $error-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.font-increased {
  font-size: 1.3rem;
}

/** Block text styling (for consents etc.) **/

p.block-text {
  color: $mildly-light !important;
  font-size: 0.964rem;
  text-align: justify;
  margin-bottom: 0;
  padding: 0.7em;
  -ms-word-break: break-word;
  word-break: break-word;
}

p.block-text.no-html {
  white-space: pre-wrap;
}

p.block-text {
  * {
    color: $mildly-light !important;
    font-size: 0.964rem;
  }

  strong {
    font-weight: bold;
  }
}

strong {
  font-weight: bold;
}
